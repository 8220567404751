<!-- Based on: https://gist.github.com/sayhicoelho/f9593e22072ececc469ffbce536ac209 -->

<template>
    <div :class="['accordion-item', { expanded: isExpanded, collapsing: isCollapsing }]" ref="outerDiv">
        <div class="accordion-header" @click="() => toggle(false)">
            <div class="arrow-container">
                <!--<div :class="['arrow', isExpanded ? 'arrow-down' : '']">&#187;</div>-->
                <img class="arrow arrow-down" src="/images/components/accordion/accordion-arrow-down.jpg" width="19" height="18" loading="lazy" />
                <img class="arrow arrow-left" src="/images/components/accordion/accordion-arrow-left.jpg" width="18" height="16" loading="lazy"  />
            </div>
            <div class="header-text-container">
                <div class="header-text">{{ headerText }}</div>
            </div>
        </div>
        <div :class="['accordion-content']" :style="heightStyle" ref="contentDiv">
            <div class="accordion-content-inner">
                <slot name="content" />
            </div>
        </div>
    </div>
</template>

<style lang="scss"  scoped>
    @import "../styles/media-query-vars.scss";

    .accordion-item {
        border: 1px solid rgb(202, 217, 11);
        margin: 3px 0;

        .accordion-header {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            cursor: pointer;
            padding: 0.5rem;
            color: rgb(137, 147, 150);
            font-weight: bold;
            font-size: larger;
            background-image: url('/images/components/common/plain-background-small.jpg');
            background-size: 100% auto;

            @media #{$mq-above800} {
                background-image: url('/images/components/common/plain-background.jpg');
            }

            @media #{$mq-above1024} {
                padding: 1rem;
            }

            & .arrow-container {
                margin: 0 0 0 0.5rem;
                width: 2rem;

                & .arrow {

                    &.arrow-down {
                        display: none;
                    }
                }
            }

            &:hover {
                filter: brightness(70%)
            }
        }

        .header-text-container {
            .header-text {
                display: inline-block;
            }
        }

        .accordion-content {
            padding: 1rem;
            transition: height 200ms ease;
        }

        &.expanded {
            .accordion-header {
                background-image: none;
                background-color: rgb(202, 217, 11);
                color: white;
            }

            & .arrow.arrow-down {
                display: inline !important;
            }

            & .arrow.arrow-left {
                display: none;
            }
        }

        &:not(.expanded):not(.collapsing) {
            & .arrow.arrow-down {
            }

            & .arrow.arrow-left {
            }

            & .accordion-content {
                display: none;
            }
        }

        &.collapsing .accordion-content {
            height: 0;
            overflow: hidden;
        }
    }

    /*
    .accordion-item.isExpanded > .accordion-header,
    .accordion-header:hover {
        background-color: #f2f2f2;
        color: rgb(202, 217, 11);
    }
    */
    
</style>

<script>
    export default {
        name: 'AccordionItem',
        props: {
            headerText: {
                type: String,
            },
            autoScroll: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                isExpanded: false,
                isCollapsing: false,
                height: null,
            }
        },
        computed: {
            heightStyle() {
                if (this.height !== null) {
                    return `height: ${this.height}px;`
                }
                return ''
            }
        },
        methods: {
            startCollapsing() {
                this.height = this.$refs.contentDiv.scrollHeight
                this.isCollapsing = true
                this.isExpanded = false                
                setTimeout(() => {
                    this.height = null
                }, 5)
                setTimeout(() => {
                    this.isExpanded = false
                    this.isCollapsing = false
                }, 200)
                this.$emit("collapsing", { outerDivElement: this.$refs.outerDiv })
            },
            scrollIntoView() {
                this.$refs.outerDiv.scrollIntoView({ behavior: "smooth", block: "start" })
            },
            collapse(skipNeighbourCollapse) {
                if (this.isExpanded)
                    this.toggle(skipNeighbourCollapse)
            },
            expand(skipNeighbourCollapse, continuationFn) {
                if (this.isExpanded) {
                    if (continuationFn)
                        continuationFn()
                }
                else
                    this.toggle(skipNeighbourCollapse, continuationFn)
            },
            toggle(skipNeighbourCollapse, expandContinuationFn) {

                if (this.isCollapsing)
                    return

                if (!this.isExpanded && this.autoScroll) {
                    setTimeout(() => {
                        window.scrollTo({
                            top: this.$refs.outerDiv.offsetTop - 8,
                            behavior: "smooth"
                        })
                    }, 250)
                }
                if (!this.isExpanded) {
                    if (!skipNeighbourCollapse && !this.$parent.multiple)
                        this.$parent.collapseAllAccordionItemsExcept(this)

                    this.isCollapsing = true
                    this.$nextTick(() => {
                        this.height = this.$refs.contentDiv.scrollHeight
                    })
                    setTimeout(() => {
                        this.isExpanded = true
                        this.height = null
                        this.isCollapsing = false

                        if (expandContinuationFn)
                            expandContinuationFn()

                    }, 200)
                } else {
                    this.startCollapsing()
                }
            }
        },
        mounted() {
            this.$parent.registerAccordionItem(this)
        },
    }
</script>

