<template>
    <div ref="outerDiv" class="instrument-selection secondary-colors">
        <div v-if="instrumentPresentations.length > 0" class="common">
            <a class="arrow" href="javascript:;" v-on:click="selectPreviousInstrument()">
                <img :class="selectedInstrumentIndex === 0 ? 'hidden' : 'mirrored'" :src="require('../assets/arrow.png')" loading="lazy" />
            </a>
            <div class="img-wrapper">                
                <img v-if="selectedInstrumentIndex > 0" :src="getImageFilePath(selectedInstrumentIndex - 1)" @click="selectPreviousInstrument()" :title="instrumentPresentations[selectedInstrumentIndex - 1].instrument.name" loading="lazy" />                
            </div>
            <div class="selected">
                <div class="img-wrapper">
                    <img :src="getImageFilePath(selectedInstrumentIndex)" :title="selectedInstrument.name" loading="lazy" />
                </div>
                <div class="presentation">
                    <span>{{ selectedInstrument.name }}:</span>
                    <br />
                    <span>{{ selectedInstrument.tagline }}</span>
                </div>
                <div class="button-wrapper">
                    <button class="button" v-on:click="setExpandedInstrument(selectedInstrumentIndex)">Mehr erfahren</button>
                </div>
            </div>
            <div class="img-wrapper">
                <img v-if="selectedInstrumentIndex < instrumentPresentations.length - 1" :src="getImageFilePath(selectedInstrumentIndex + 1)" @click="selectNextInstrument()" :title="instrumentPresentations[selectedInstrumentIndex + 1].instrument.name" loading="lazy" />
            </div>
            <a class="arrow" href="javascript:;" v-on:click="selectNextInstrument()">                
                <img :class="selectedInstrumentIndex === instrumentPresentations.length - 1 ? 'hidden' : null" :src="require('../assets/arrow.png')" loading="lazy" />                
            </a>
        </div>        
    </div>
        
    <div class="instrument-accordion-wrapper">
        <AccordionGroup :multiple="false" ref="instrumentPresentationsAccordionGroup">
            <template v-slot:default>
                <AccordionItem v-for="instrumentPresentation in instrumentPresentations" :key="instrumentPresentation.instrument.id" :headerText="instrumentPresentation.instrument.name" class="card" v-on:collapsing="handleInstrumentPresentationAccordionItemCollapsing">
                    <template v-slot:content>
                        <ContentItem v-for="(contentItem, contentItemIndex) in instrumentPresentation.contentItems" :key="contentItemIndex" :dataSource="contentItem">
                        </ContentItem>
                    </template>
                </AccordionItem>
            </template>
        </AccordionGroup>
    </div>
</template>

<style lang="scss" scoped>
    @import "../styles/media-query-vars.scss";

    .instrument-selection {
        border: 1px solid lightgray;
        text-align: center;
        vertical-align: middle;

        & > div.common {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;
            margin: 0 0.5rem;

            @media #{$mq-above640} {
                gap: 1rem;
            }

            @media #{$mq-above1024} {
                margin: 0 1rem;
                gap: 3rem;
            }

            & a.arrow {
                position: relative;
                top: 7.5rem;
            }

            & > .img-wrapper {
                width: 226px;
                height: 160px;
                display: none;
                justify-content: center;
                align-items: center;
                position: relative;
                top: 3rem;

                &.selected {
                    display: flex;
                }

                & img {
                    width: auto;
                    height: 160px;
                    cursor: pointer;
                }

                @media #{$mq-above1024} {
                    &:not(.selected) {
                        display: flex;
                    }
                }
            }
        }

        & div.selected {
            position: relative;
            top: -6rem;
            width: 350px;


            & .img-wrapper {
                height: 160px;
                display: flex;
                justify-content: center;
                align-items: center;

                @media #{$mq-above1024} {
                    height: 250px;
                }

                & img {
                    width: auto;
                    height: 160px;

                    @media #{$mq-above1024} {
                        height: 250px;
                    }
                }
            }

            & .table-wrapper {
                padding: 0.8rem;
            }

            & table {
                width: 100%;
            }

            & th {
                text-align: right;
                vertical-align: top;
                padding: 0.2rem;
            }

            & td {
                text-align: left;
                vertical-align: top;
                width: 45%;
                padding: 0.2rem;
            }

            & .presentation {
                color: rgb(147, 157, 129);
                font-size: larger;

                & span:first-child {
                    text-transform: uppercase;
                }
            }

            & .button-wrapper {
                margin: 1rem 0;
            }
        }

        a.arrow {
            cursor: default;

            & img {
                width: 50px;
                height: 35px;
                cursor: pointer;

                &.hidden {
                    cursor: default;
                    visibility: hidden;
                }

                &.mirrored {
                    -webkit-transform: scaleX(-1);
                    transform: scaleX(-1);
                }
            }
        }
    }

    .instrument-accordion-wrapper {
        margin: 1rem 0;

        @media #{$mq-above800} {
            margin: 1rem;
        }

        @media #{$mq-above1024} {
            margin: 5rem;
        }
    }

</style>
<script>
    import { cmsApiAxios, handleCmsApiError } from '../cms-api/cms-api'
    import { TouchHelper } from '../helpers/touch-helper'
    import YouTubeVideoHelper from '../helpers/youtube-video-helper'    
    import AccordionGroup from './AccordionGroup.vue'
    import AccordionItem from './AccordionItem.vue'
    import ContentItem from './ContentItem.vue'

    export default {
        name: "InstrumentSelection",
        components: {
            AccordionGroup,
            AccordionItem,
            ContentItem,
        },
        data() {
            return {
                selectedInstrumentIndex: 0,
                expandedInstrumentIndex: -1,
                instrumentPresentations: []
            }
        },
        computed: {
            selectedInstrument() {
                return this.instrumentPresentations[this.selectedInstrumentIndex].instrument
            },
        },
        methods: {
            getImageFilePath(instrumentIndex) {
                const instrument = this.instrumentPresentations[instrumentIndex].instrument;

                return `/images/instruments/${instrument.id}/${instrument.id}-selection.png`;
            },
            selectPreviousInstrument() {
                if (this.selectedInstrumentIndex > 0)
                    this.selectedInstrumentIndex--
            },
            selectNextInstrument() {
                if (this.selectedInstrumentIndex < this.instrumentPresentations.length - 1)
                    this.selectedInstrumentIndex++
            },
            setExpandedInstrument(instrumentIndex) {
                const instrumentPresentationsAccordionGroup = this.$refs.instrumentPresentationsAccordionGroup;
                instrumentPresentationsAccordionGroup.expandAccordionItem(instrumentIndex, true)
            },
            trySetExpandedInstrumentViaHash() {
                if (window.location.hash.length === 0)
                    return;

                const instrumentId = window.location.hash.substring(1);

                for (var i = 0; i < this.instrumentPresentations.length; i++) {
                    const instrument = this.instrumentPresentations[i].instrument;                    

                    if (instrument.id === instrumentId) {
                        this.setExpandedInstrument(i);
                        break;
                    }
                }
            },
            loadInstrumentPresentations() {
                return cmsApiAxios.get('/cms/instrument-presentation')
                                  .then(response => {
                                       this.instrumentPresentations = response.data.filter(ip => ip.isEnabled !== false);                               
                                  })
                                  .catch(error => handleCmsApiError(error))
            },
            handleInstrumentPresentationAccordionItemCollapsing(e) {
                // Make sure to stop all YouTube videos that are inside the collapsing Accordion
                YouTubeVideoHelper.stopAll(e.outerDivElement)
            }
        },
        mounted() {
            new TouchHelper().addTouchHandler(this.$refs.outerDiv, e => {
                if (e.isSwipeRight())
                    this.selectPreviousInstrument()
                else if (e.isSwipeLeft())
                    this.selectNextInstrument()
            })

            this.loadInstrumentPresentations().then(() => this.trySetExpandedInstrumentViaHash());
            window.addEventListener('hashchange', () => this.trySetExpandedInstrumentViaHash());
        },
    }    
</script>