<template>
    <div class="outer-adobe-animation-container">
        <div class="inner-adobe-animation-container">
            <iframe class="adobe-animation" :src="adobeAnimationFilePath" scrolling="no">
            </iframe>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../styles/media-query-vars.scss";

    .outer-adobe-animation-container {
        @media #{$mq-above640} {            
        }
    }

    .inner-adobe-animation-container {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
    }

    .adobe-animation {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
        overflow: hidden;

        @media only screen and (max-width: 799px) {
            /*
            -ms-transform: scale(0.9);
            -moz-transform: scale(0.9);
            -o-transform: scale(0.9);
            -webkit-transform: scale(0.9);
            transform: scale(0.9);
            -ms-transform-origin: 0 0;
            -moz-transform-origin: 0 0;
            -o-transform-origin: 0 0;
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            */
        }
    }
</style>
<script>
    export default {
        name: 'AdobeAnimation',
        props: {
            adobeAnimationId: {
                type: String,
            },
        },
        computed: {
            adobeAnimationFilePath() {                
                return `${process.env.BASE_URL}adobe-animations/${this.adobeAnimationId}/${this.adobeAnimationId}.html`
            }
        },
    }
</script>
<style scoped>
</style>
