<template>
    <div v-if="stageAnimations.length > 0" class="stage">
        <adobe-animation ref="stageAdobeAnimation" :adobeAnimationId="selectedStageAnimation.adobeAnimationId"></adobe-animation>        
        <div class="button-panel">
            <button v-for="(stageAnimation, stageAnimationIndex) in stageAnimations" :key="stageAnimationIndex" :class="getButtonCssClass(stageAnimationIndex)" v-on:click="selectStageAnimation(stageAnimationIndex)">
                {{ stageAnimation.musicGroup.name }}
            </button>
        </div>
    </div>    
</template>

<style lang="scss" scoped>
    @import "../styles/media-query-vars.scss";

    .stage {
        display: flex;
        flex-direction: column;
        width: 100vw;
        max-width: 100%;
        font-size: xx-large;
        text-align: center;
        vertical-align: middle;        
    }

    .button-panel {
        display: flex;
        flex-direction: column;
        justify-content: center;

        & button {
            margin-top: 0.5rem;
        }

        @media #{$mq-above1024} {
            margin-top: 0.5rem;
            flex-direction: row;

            & button {
                margin-top: 0;

                & + button {
                    margin-left: 0.5rem;
                }
            }
        }
    }

</style>
<script>
    import { cmsApiAxios, handleCmsApiError } from '../cms-api/cms-api'
    import YouTubeVideoHelper from '../helpers/youtube-video-helper'
    import AdobeAnimation from './AdobeAnimation.vue'

    export default {
        name: "Stage",
        components: {
            AdobeAnimation,
        },
        data() {
            return {
                selectedStageAnimationIndex: 0,
                stageAnimations: []
            }
        },
        computed: {
            selectedStageAnimation() {
                return this.stageAnimations[this.selectedStageAnimationIndex]
            },
        },
        methods: {
            getButtonCssClass(stageAnimationIndex) {
                let buttonCssClass = "button"

                if (stageAnimationIndex !== this.selectedStageAnimationIndex)
                    buttonCssClass += " unselected";

                return buttonCssClass
            },
            loadStageAnimations() {
                cmsApiAxios.get('/cms/stage-animation')
                    .then(response => this.stageAnimations = response.data)
                    .catch(error => handleCmsApiError(error))
            },
            selectStageAnimation(stageAnimationIndex) {
                YouTubeVideoHelper.stopAll()
                this.selectedStageAnimationIndex = stageAnimationIndex
            }
        },
        mounted() {
            this.loadStageAnimations()
        },
    }
</script>