<template>
    <div v-if="musicGroup" class="contact primary-colors font-size-larger">
        <div class="upper-part secondary-colors">
            <div class="top-line heading-colors font-size-xx-large">Na, bist du dabei?</div>
            <div>
                Nimm einfach Kontakt mit uns auf, wenn du weitere Informationen brauchst oder du dich für den Instrumentalunterricht anmelden möchtest. Wir freuen uns auf dich.                
            </div>
        </div>

        <div class="lower-part">
            <div class="first-line">
                <span>E-Mail: <br class="small" /><a :href="'mailto:' + musicGroup.emailAddress">{{ musicGroup.emailAddress }}</a></span>
                <span class="still-notes-img-wrapper">
                    <img class="large" src="../assets/still-notes.png" loading="lazy" />
                </span>
            </div>
            <div class="second-line">
                &nbsp;
            </div>
            <div class="third-line">
                Telefon: <br class="small" />{{ musicGroup.phoneNumber }}
                <span class="still-notes-img-wrapper">
                    <img class="small" src="../assets/still-notes-small.png" loading="lazy" />
                </span>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../styles/media-query-vars.scss";

    .contact {
        & .upper-part {
            padding: 5rem 0 5rem 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;

            & .top-line {
                margin-bottom: 0.5rem;
            }

            @media #{$mq-above1024} {
                padding: 6rem 0 6rem 0;

                & > div {
                    max-width: 50vw;
                }
            }
        }

        & .lower-part {
            width: 100vw;
            max-width: 100%;
            padding: 3rem 0 3rem 0;
            text-align: center;
            background-image: url('/images/components/common/plain-background-small.jpg');
            background-size: 100% auto;

            @media #{$mq-above800} {
                background-image: url('/images/components/common/plain-background.jpg');
            }

            .small {
                display: inline-block;
            }

            & .first-line {
                & .still-notes-img-wrapper {
                    position: relative;

                    & img {
                        position: absolute;

                        &.large {
                            left: 0px;
                            top: -40px;
                            width: 200px;
                            height: 177px;
                            text-align: center;
                            display: none;
                        }

                        @media #{$mq-above1024} {
                            &.large {
                                display: inherit;
                            }
                        }
                    }
                }
            }

            & .third-line {
                & .still-notes-img-wrapper {
                    position: relative;

                    & img {
                        position: absolute;

                        &.small {
                            left: 30px;
                            top: -50px;
                            width: 100px;
                            height: 89px;
                            text-align: center;
                        }

                        @media #{$mq-above1024} {
                            &.small {
                                display: none;
                            }
                        }
                    }
                }
            }

            @media #{$mq-above1024} {
                padding: 5rem 0 5rem 0;

                & .small {
                    display: none;
                }
            }
        }
    }    

</style>
<script>    
    import { cmsApiAxios, handleCmsApiError } from '../cms-api/cms-api'

    export default {
        name: "Contact",
        props: {
            musicGroupId: {
                type: String
            }
        },
        data() {
            return {
                musicGroup: null
            }
        },
        methods: {            
            loadInstrumentPresentations() {
                cmsApiAxios.get('/cms/music-group/' + this.musicGroupId)
                           .then(response => this.musicGroup = response.data)
                           .catch(error => handleCmsApiError(error));
            }
        },
        mounted() {
            this.loadInstrumentPresentations();
        },
    }
</script>