<template>
    <div class="tadaa secondary-colors font-size-larger">        
        <div class="first-line">
            Tadaaa... Wir haben ein
        </div>
        <div class="second-line font-size-xxx-large">
            <span class="heading-colors">Musikstück</span>
            <span class="animated-notes-img-wrapper">
                <img class="small" src="../assets/animated-notes-small.gif" loading="lazy" />
                <img class="large" src="../assets/animated-notes.gif" loading="lazy" />
            </span>
        </div>
        <div class="third-line">
            für dich eingeübt.
        </div>        
    </div>
</template>

<style lang="scss" scoped>
    @import "../styles/media-query-vars.scss";

    .tadaa {
        width: 100vw;
        max-width: 100%;
        padding: 3rem 0 3rem 0;

        @media #{$mq-above1024} {
            padding: 5rem 0 5rem 0;
        }

        & .first-line {
            text-align: center;
            margin-bottom: 1rem;

            @media #{$mq-above1024} {
                margin-bottom: 3rem;
            }
        }

        & .second-line {
            text-align: center;

            & .animated-notes-img-wrapper {
                position: relative;

                & img {
                    position: absolute;

                    &.small {
                        left: 10px;
                        top: -15px;
                        width: 50px;
                        height: 44px;
                    }

                    &.large {
                        left: 10px;
                        top: -100px;
                        width: 150px;
                        height: 133px;
                        text-align: center;
                        display: none;
                    }

                    @media #{$mq-above1024} {
                        &.small {
                            display: none;
                        }

                        &.large {
                            display: inherit;
                        }
                    }
                }
            }
        }

        & .third-line {
            margin-top: 1rem;
            text-align: center;

            @media #{$mq-above1024} {
                margin-top: 2rem;
            }
        }
    }
</style>
<script>
    export default {
        name: "Tadaa"
    }
</script>