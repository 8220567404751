<template>
    <div class="instrument-instructions primary-colors">
        <div class="top-line heading-colors font-size-xx-large">Was ist was?</div>
        <div class="font-size-larger">Jedes Instrument hat einen eigenen Klang und besondere Eigenschaften. Klicke dich durch die verschiedenen Instrumenten-Bilder, um die STECKBRIEFE zu sehen.</div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../styles/media-query-vars.scss";

    .instrument-instructions {
        width: 100vw;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 3rem 0 10rem 0;

        & .top-line {
            margin-bottom: 0.5rem;
        }

        @media #{$mq-above1024} {
            margin: 6rem 0 12rem 0;

            & > div {
                max-width: 50vw;
            }
        }
    }

</style>
<script>
    export default {
        name: "InstrumentsInstruction"
    }
</script>