<template>
    <component v-bind:is="innerComponent" v-bind="dataSourceWithoutComponentName"></component>
</template>

<script>    
    import Image from "./Image.vue"
    import Interview from "./Interview.vue"
    import RenderedHtml from "./RenderedHtml.vue"
    import RenderedMarkdown from "./RenderedMarkdown.vue"
    import YouTubeVideo from "./YouTubeVideo.vue"

    export default {
        name: "ContentItem",
        props: {
            dataSource: null
        },
        computed: {
            innerComponent() {
                switch (this.dataSource.componentName) {
                    case "Image":
                        return Image;
                    case "Interview":
                        return Interview
                    case "RenderedHtml":
                        return RenderedHtml
                    case "RenderedMarkdown":
                        return RenderedMarkdown
                    case "YouTubeVideo":
                        return YouTubeVideo
                    default:
                        return null
                }
            },
            dataSourceWithoutComponentName() {
                let clonedDataSource = Object.assign({}, this.dataSource)

                delete clonedDataSource.componentName
                
                return clonedDataSource
            },
        }
    }
</script>