<template>
    <div class="image-container">
        <picture v-if="smallImageUrl">
            <source :srcset="imageUrl" :width="imageWidth" :height="imageHeight" media="(min-width: 1024px)" />
            <img :src="smallImageUrl" :width="smallImageWidth" :height="smallImageHeight" :alt="alt" loading="lazy" />
        </picture>
        <img v-if="!smallImageUrl" :src="imageUrl" :width="imageWidth" :height="imageHeight" :alt="alt" loading="lazy" />
    </div>
</template>

<style lang="scss" scoped>
    @import "../styles/media-query-vars.scss";

    .image-container {
        margin-bottom: 0.7rem;

        & img {
            width: 100%;
            height: auto;

            @media #{$mq-above640} {
                max-width: 640px;
            }
        }        
    }
</style>

<script>
    export default {
        name: 'Image',
        props: {
            imageUrl: {
                type: String,
                required: true
            },
            imageWidth: {
                type: Number,
                required: true
            },
            imageHeight: {
                type: Number,
                required: true
            },
            smallImageUrl: {
                type: String
            },
            smallImageWidth: {
                type: Number
            },
            smallImageHeight: {
                type: Number
            }
        }
    }
</script>