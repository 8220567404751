<!-- Based on: https://gist.github.com/sayhicoelho/f9593e22072ececc469ffbce536ac209 -->

<template>    
    <div>
        <div class="accordion-group">
            <slot />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AccordionGroup',
        props: {
            multiple: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                accordionItems: []
            }
        },
        methods: {
            registerAccordionItem(accordionItem) {
                this.accordionItems.push(accordionItem)
            },
            collapseAllAccordionItemsExcept(exceptedAccordionItem) {
                this.accordionItems.map(accordionItem => {
                    if (accordionItem !== exceptedAccordionItem)
                        accordionItem.collapse(true)
                })
            },
            expandAccordionItem(accordionItemIndex, scrollAccordionItemIntoView) {
                const accordionItem = this.accordionItems[accordionItemIndex];
                accordionItem.expand(false, () => {
                    if (scrollAccordionItemIntoView)
                        accordionItem.scrollIntoView()
                })
            },
        }
    }
</script>