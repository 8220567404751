<template>
    <div class="top-header font-size-large primary-colors">
        <img class="logo" :src="require('../assets/logo.png')" loading="lazy" />
        <div class="memo-1-wrapper font-size-larger">
            <div>
                <span class="font-size-x-large heading-colors">Hey, du!</span>
                <br />
                <br />
                Willkommen bei FINDSTRUMENT.
            </div>
        </div>
        <div class="memo-2-wrapper">
            <div>
                Hier stellen wir dir
                alle Instrumente unseres
                Musikvereins vor. Wenn
                du Lust hast, kannst du
                bei uns gerne eins dieser
                Musikinstrumente lernen.
                <br />
                <br />
                Erkennst du schon
                Instrumente an den
                Umrissen?
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../styles/media-query-vars.scss";

    .top-header {
        position: relative;
        width: 100vw;
        max-width: 100%;
        height: 30vh;
        background-image: url('/images/components/top-header/instrument-background.jpg');
        background-size: 100% auto;

        & img.logo {
            margin-left: 1rem;
            margin-top: 1rem;
            width: 50vw;
        }

        & div.memo-1-wrapper {
            display: none;
        }

        & div.memo-2-wrapper {
            display: none;
        }

        @media #{$mq-above1024} {
            height: 100vh;

            & img.logo {
                margin: 3rem 0 0 3rem;
                width: 33vw;
            }

            & div.memo-1-wrapper {
                position: absolute;
                display: inline-block;
                right: 185px;
                bottom: 270px;
                width: 500px;
                height: 497px;
                background-image: url('~@/assets/memo-1.png');
                background-size: 100% 100%;

                & div {
                    margin: 34px 42px 0 40px;
                    padding: 10rem 2.3rem 0 2.3rem;
                    transform: rotate(0.9deg);
                    /*
                    background-color: rgba(255, 0, 0, 0.3);
                    */
                }
            }

            & div.memo-2-wrapper {
                position: absolute;
                display: inline-block;
                right: 0;
                bottom: -80px;
                width: 500px;
                height: 497px;
                background-image: url('~@/assets/memo-2.png');
                background-size: 100% 100%;
                font-size: 1.58rem;

                & div {
                    margin: 46px 54px 0 41px;
                    padding: 5rem 2.3rem 0 2.3rem;
                    transform: rotate(-2.6deg);
                    /*
                    background-color: rgba(0, 0, 255, 0.3);
                    */
                }

                @media #{$mq-above1024} {
                }
            }
        }
    }

</style>

<script >
export default {
    name: 'TopHeader',
    components: {
    }
}
</script >
