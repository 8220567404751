<template>  
  <router-view />
</template>

<script>
    export default {
      name: 'App',
      components: {
      }
    }
</script>

<style lang="scss">
    @import "./styles/media-query-vars.scss";

    @font-face {
        font-family: "SpecialElite";
        src: local("SpecialElite"), url(./fonts/SpecialElite/SpecialElite-Regular.ttf) format("truetype");
    }

    html, body {
        color: rgb(202, 217, 11);
        font-family: "SpecialElite", Arial, Helvetica, sans-serif;
        margin: 0;
        overflow-x: hidden;
    }

    /*
    #app {
    }
    */

    .button {
        padding: 0.5rem 1.5rem;
        background-color: white;
        color: rgb(147, 157, 129);
        border: 1px solid rgb(202, 217, 11);
        cursor: pointer;
        text-decoration: none;
        display: inline-block;

        &.unselected {
            background-color: rgb(202, 217, 11);
            color: rgb(255 255 255);
            border: 1px solid white;
        }
    }

    .font-size-normal {
        font-size: 1rem;
    }

    .font-size-large {
        font-size: 1.2rem;

        @media #{$mq-above1024} {
            font-size: 1.3rem;
        }
    }

    .font-size-larger {
        font-size: 1.4rem;

        @media #{$mq-above1024} {
            font-size: 2rem;
        }
    }

    .font-size-x-large {
        font-size: 1.6rem;

        @media #{$mq-above1024} {
            font-size: 4rem;
        }
    }

    .font-size-xx-large {
        font-size: 1.8rem;

        @media #{$mq-above1024} {
            font-size: 5.5rem;
        }
    }

    .font-size-xxx-large {
        font-size: 2rem;

        @media #{$mq-above1024} {
            font-size: 7rem;
        }
    }

    .primary-colors {
        background-color: rgb(255, 255, 255);
        color: rgb(137, 147, 150);

        & .heading-colors, & h1, & h2, & h3 {
            color: rgb(202, 217, 11);
            /*
            font-weight: bold;
            */
        }
    }

    .secondary-colors {
        background-color: rgb(202, 217, 11);
        color: rgb(137, 147, 150);

        & .heading-colors, & h1, & h2, & h3, & a {
            color: rgb(255, 255, 255);
            /*
            font-weight: bold;
            */
        }
    }

    .tertiary-colors {
        background-color: rgb(242, 233, 226);
        color: rgb(0, 0, 0);

        & .heading-colors, & h1, & h2, & h3 {
            color: rgb(157, 165, 9);
            /*
            font-weight: bold;
            */
        }
    }
    

</style>
