<template>
    <div class="parent-message primary-colors font-size-larger">
        <div class="top-line heading-colors font-size-xx-large">Liebe Eltern,</div>
        <div>
            Musizieren wirkt sich positiv auf das Sozialverhalten aus: 
            Ich kann nur dann mit anderen zusammenspielen, 
            wenn wir einander zuhören und aufeinander Rücksicht nehmen.
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../styles/media-query-vars.scss";

    .parent-message {
        padding: 5rem 0 5rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        & .top-line {
            margin-bottom: 0.5rem;
        }

        @media #{$mq-above1024} {
            padding: 6rem 0 6rem 0;

            & > div {
                max-width: 50vw;
            }
        }
    }

</style>
<script>
    export default {
        name: "ParentMessage"
    }
</script>