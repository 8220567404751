import axios from 'axios'

function getCmsBaseApiUrl() {
    return process.env.NODE_ENV === 'development' ? 'http://localhost:5000' : ''
}

export const cmsApiAxios = axios.create({
    baseURL: getCmsBaseApiUrl()
});

export function handleCmsApiError(error) {
    alert(error)
}