<template>
    <div class="primary-colors">
        <vue3-markdown-it :source="markdown" />
    </div>
</template>

<script>
    import Vue3MarkdownIt from 'vue3-markdown-it'

    export default {
        name: 'RenderedMarkdown',
        props: {
            markdown: {
                type: String
            }
        },
        components: {
            Vue3MarkdownIt,
        },
    }
</script>