<template>
    <div :class="['interview', { alternative: this.useAlternativeColors, background: this.useBackground }]">
        <div class="interviewee">
            <div class="round-img-container">
                <img :src="require('../assets/people/' + interview.intervieweePerson.imageFileName)" :title=" interview.intervieweePerson.name" loading="lazy" />
            </div>
            <div class="speech-bubble">
                {{ interview.intervieweePerson.personalQuote }}
            </div>
        </div>
        <div class="questions-and-answers">
            <div v-for="(questionAndAnswer, questionAndAnswerIndex) in interview.questionsAndAnswers" :key="questionAndAnswerIndex" class="question-and-answer">
                <h3 class="font-size-large">
                    <img class="quote" src="/images/components/interview/interview-quote.jpg" width="40" height="33" loading="lazy" />
                    <img class="quote quote-alternative" src="/images/components/interview/interview-quote-alternative.jpg" width="40" height="33" loading="lazy" />
                    <span>{{ questionAndAnswer.question }}</span>
                </h3>
                <p>{{ questionAndAnswer.answer }}</p>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../styles/media-query-vars.scss";

    $round-img-radius: 75;
    $round-img-diameter: $round-img-radius * 2;
    $quote-img-width: 40;

    .interview {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        min-height: 15rem;
        background-color: rgb(255, 255, 255);
        color: rgb(137, 147, 150);
        padding: 1rem;

        @media #{$mq-above1024} {
            flex-direction: row-reverse;
            justify-content: center;
            padding: 3rem 0;
        }

        .questions-and-answers {
            padding: 0 0 0 3rem;

            & div + div {
                margin-top: 2rem;
            }

            @media #{$mq-above1024} {
                padding: 0 5rem 0 0;
                width: 50vw;

                & div + div {
                    margin-top: 3rem;
                }
            }

            .question-and-answer {
                h3 {
                    position: relative;
                    margin-block-start: 0;
                    margin-block-end: 0;
                    margin-inline-start: 0;
                    margin-inline-end: 0;

                    & .quote {
                        position: absolute;
                        z-index: 0;
                        top: -7px;
                        left: #{-$quote-img-width + 5}px;

                        &.quote-alternative {
                            display: none;
                        }
                    }

                    & span {
                        position: relative;
                        z-index: 1;
                    }
                }
            }
        }

        .interviewee {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-shrink: 0;

            @media #{$mq-above1024} {
                flex-direction: column;
            }

            .round-img-container {
                flex-shrink: 0;
                width: #{$round-img-diameter - 20}px;
                height: #{$round-img-diameter}px;
                padding-bottom: 1rem;

                @media #{$mq-above1024} {
                    width: #{$round-img-diameter}px;
                    height: #{$round-img-diameter - 20}px;
                    padding-bottom: 0;
                }

                & img {
                    position: absolute;
                    width: #{$round-img-diameter}px;
                    height: #{$round-img-diameter}px;
                    border-radius: 50%;
                    z-index: 1;
                }
            }

            .speech-bubble {
                position: relative;
                top: 1.5rem;
                text-align: center;
                border-radius: 10px;
                padding: 0.9rem 0.5rem 0.7rem 0.5rem;
                background-color: rgb(242, 233, 226);
                color: rgb(0, 0, 0);
                z-index: 2;

                @media #{$mq-above1024} {
                    top: 0;
                    width: #{$round-img-diameter + 30}px;
                }
            }
        }

        &.alternative {
            background-color: rgb(202, 217, 11);
            color: rgb(137, 147, 150);

            &.background {
                background-image: url('/images/components/common/plain-background-small.jpg');
                background-size: 100% auto; 

                @media #{$mq-above800} {
                    background-image: url('/images/components/common/plain-background.jpg');
                }
            }

            @media #{$mq-above1024} {
                flex-direction: row;
            }

            & .questions-and-answers {
                @media #{$mq-above1024} {
                    padding: 0 0 0 5rem;
                }
            }

            & .quote {
                &:not(.quote-alternative) {
                    display: none;
                }

                &.quote-alternative {
                    display: inline !important;
                }
            }

            & .speech-bubble {
                background-color: rgb(255, 255, 255);
                color: rgb(137, 147, 150);
            }
        }
    }    

</style>

<script>
    export default {
        name: 'Interview',
        props: {
            useAlternativeColors: {
                type: Boolean,
                default: false,
            },
            useBackground: {
                type: Boolean,
                default: false,
            },
            interview: null,
        },
        components: {            
        },
    }
</script>