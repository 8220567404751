<template>
    <div class="steps">        
        <div class="step">
            <img src="../assets/step-1.png" loading="lazy" />
            <div class="description">Wir spielen für dich, wenn du auf den PLAY-BUTTON unten klickst. Bühne frei!</div>
        </div>
        <div class="step">
            <img src="../assets/step-2.png" loading="lazy" />
            <div class="description">Du kannst einzelne Musiker pauisieren lassen, indem du auf sie klickst. So kannst du die einzelnen Stimmen besser hören.</div>
        </div>
        <div class="step">
            <img src="../assets/step-3.png" loading="lazy" />
            <div class="description">Wähle einen der verschiedenen Musikstile unten aus. Viel Spaß beim Ausprobieren!</div>
        </div>        
    </div>
</template>

<style lang="scss" scoped>
    @import "../styles/media-query-vars.scss";

    .steps {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        background-color: rgb(255, 255, 255);
        padding: 3rem 0;

        @media #{$mq-above1024} {
            padding: 5rem 0;
            flex-direction: row;
        }
    }

    .step {
        text-align: left;
        padding: 0 1.5rem 0rem 1.5rem;

        @media #{$mq-above1024} {
            padding: 0 3rem 0 3rem;
            width: 30vw;
        }

        & img {
            width: 32vh;
            max-width: 350px;
            height: auto;

            @media #{$mq-above1024} {
                width: 100%;
            }
        }

        & .description {
            margin-top: 1rem;
            color: rgb(137, 147, 150);
            min-height: 10vh;

            @media #{$mq-above1024} {
                min-height: inherit;
            }
        }
    }    

</style>
<script>
    export default {
        name: "Steps"
    }
</script>