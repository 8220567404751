<template>
    <div class="imprint">
        <content-section contentSectionId="imprint" />
    </div>
</template>

<style scoped>

    .imprint {
        margin: 1rem;
    }

</style>
<script>
    import ContentSection from './ContentSection.vue'

    export default {
        name: "Imprint",
        components: {
            ContentSection
        },
    }
</script>