<template>
    <div class="more-info primary-colors font-size-larger">        
        <img class="large" src="../assets/smiley.jpg" loading="lazy" />
        <div>Nicht nur die tollen Instrumente sind ein guter Grund,<br />in unseren örtlichen</div>
        <div class="middle-line heading-colors font-size-xx-large">
            <span>Musikverein</span>
            <span class="smiley-small-img-wrapper">
                <img src="../assets/smiley.jpg" loading="lazy" />
            </span>
        </div>
        <div>zu kommen. Bei uns kommt der Spaß in der Gemeinschaft garantiert nicht zu kurz! Ein paar Musikerinnen und Musiker berichten davon...</div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../styles/media-query-vars.scss";

    .more-info {
        margin: 5rem 0 5rem 0;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: transparent;

        img {
            position: absolute;
            z-index: -1;

            &.large {
                right: 10vw;
                width: 246px;
                height: 246px;
                display: none;
            }

            @media #{$mq-above1024} {
                &.large {
                    display: inherit;
                }
            }
        }

        .middle-line {
            margin: 1rem 0;

            & .smiley-small-img-wrapper {
                position: relative;

                & img {
                    position: absolute;                    
                    left: 20px;                                        
                    top: -20px;
                    width: 60px;
                    height: 60px;
                    text-align: center;
                }

                @media #{$mq-above1024} {
                    display: none;
                }
            }
        }

        @media #{$mq-above1024} {

            & > div {
                max-width: 50vw;
            }
        }
    }

    /* 246x246 */

</style>
<script>
    export default {
        name: "MoreInfo"
    }
</script>