<template>
    <div class="outer-video-container">
        <div class="inner-video-container">
            <iframe class="video" :src="youTubeVideoUrl" allowfullscreen>
            </iframe>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../styles/media-query-vars.scss";

    .outer-video-container {
        @media #{$mq-above640} {
            width: 640px;
        }
    }

    .inner-video-container {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
    }

    .video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
</style>

<script>
    export default {
        name: 'YouTubeVideo',
        props: {
            youTubeVideoId: {
                type: String
            }
        },
        computed: {
            youTubeVideoUrl() {
                return `https://www.youtube.com/embed/${this.youTubeVideoId}?enablejsapi=1&origin=${window.location.origin}`
            }
        }
    }
</script>