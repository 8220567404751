<template>
    <top-header></top-header>
    <tadaa></tadaa>
    <steps></steps>
    <stage></stage>
    <instrument-instructions></instrument-instructions>
    <instrument-selection></instrument-selection>
    <more-info></more-info>
    <content-section contentSectionId="more-questions"></content-section>
    <parent-message></parent-message>
    <contact musicGroupId="mv-schemmerberg"></contact>
</template>

<script>
    import TopHeader from './TopHeader.vue';
    import Tadaa from './Tadaa.vue';
    import Steps from './Steps.vue';
    import Stage from './Stage.vue';
    import InstrumentInstructions from './InstrumentInstructions.vue';
    import InstrumentSelection from './InstrumentSelection.vue';
    import MoreInfo from './MoreInfo.vue';
    import ContentSection from './ContentSection.vue';
    import ParentMessage from './ParentMessage.vue';
    import Contact from './Contact.vue';

export default {
  name: 'Home',
  components: {
      TopHeader,
      Tadaa,
      Steps,
      Stage,
      InstrumentInstructions,
      InstrumentSelection,
      MoreInfo,
      ContentSection,
      ParentMessage,
      Contact
  }
}
</script>