<template>
    <div class="primary-colors" v-html="html"></div>
</template>

<script>
    export default {
        name: 'RenderedHtml',
        props: {
            html: {
                type: String
            }
        },
        components: {
        },
    }
</script>