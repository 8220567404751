<template>
    <template v-if="contentSection">
        <ContentItem v-for="(contentItem, contentItemIndex) in contentSection.contentItems" :key="contentItemIndex" :dataSource="contentItem">
        </ContentItem>
    </template>
</template>

<script>
    import { cmsApiAxios, handleCmsApiError } from '../cms-api/cms-api'
    import ContentItem from './ContentItem.vue'

    export default {
        name: "ContentSection",
        components: {
            ContentItem,
        },
        props: {
            contentSectionId: {
                type: String
            }
        },
        data() {            
            return {
                contentSection: null
            }
        },
        methods: {            
            loadContentSection() {
                cmsApiAxios.get('/cms/content-section/' + this.contentSectionId)
                           .then(response => this.contentSection = response.data)
                           .catch(error => handleCmsApiError(error));
            }
        },
        mounted() {
            this.loadContentSection();
        },
    }
</script>